.stats {
	width: 85%;
	margin: 30px auto;
}

.title {
	& p {
		font-size: 24px;
		font-weight: bold;
		text-align: center;
		margin: 15px 0;
	}
}
@media screen and (max-width: 800px) {
	.stats {
		width: auto;
	}
}

.date {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 20px;
  margin-bottom: 40px;
}

.dateBtn {
	border-radius: 10px;
	background: #f5f5f5;
	padding: 10px 20px;
	margin: 0 10px;
	font-weight: bold;
	font-size: 16px;

	border: none;
	color: #747474;
	cursor: pointer;
}
.dateBtn:hover {
	color: red;
}

.dateBtnActive {
	background: #f4caca;
	color: red;
}

.dateText {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
