.delegat {
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 20px 50px 0px rgba(190, 131, 131, 0.1);
  border-radius: 10px;
  padding: 30px 40px;
  display: block;
  margin-top: 20px;
  position: relative;
}
// .bgClassName {
//   position: absolute;
//   width: 100%;
//   background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
//   height: 70px;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;
//   opacity: 0.9;
//   overflow: hidden;
//   & > img {
//     width: 100%;
//   }
// }

.avatar {
  & p[name="name"] {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
  & > img {
    padding: 7px;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    object-fit: contain;
    box-shadow: 0px 0px 0px 1px #f9a51a;
    background: white;
  }

  & > p[name="whereFrom"] {
    color: #3d3939;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;

  align-items: center;
}

.about {
  position: relative;
  & > div {
    display: flex;
    margin-top: 10px;
  }
}

.info {
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }
}

.bold {
  font-weight: bold;
  margin-top: 5px;
  max-width: 400px;
}

.uzor {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.t {
  margin: 60px 0;
}

.questionBlock {
  max-width: 1080px;
  border-radius: 10px;
  margin: 10px auto;
}

@media screen and (max-width: 1040px) {
  .delegat {
    width: auto;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
  }
  .questionBlock {
    width: auto;
  }

  .header {
    display: block;
    position: relative;

    margin: 0 auto;
  }

  .avatar {
    text-align: center;
  }

  .about {
    margin-top: 50px;
  }
}
@media screen and (max-width: 660px) {
  .t {
    margin: 20px 0 60px 0;
  }
  .avatar {
    & > p[name="name"] {
      font-size: 16px;
    }
    & > p[name="whereFrom"] {
      font-size: 14px;
    }
  }
  .info {
    & span {
      font-size: 14px;
    }
    & p {
      font-size: 14px;
    }
  }
}
