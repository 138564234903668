@use "shared/styles/global.scss" as *;

.select {
  position: relative;
}

.selectBtn {
  overflow: hidden;
  border: none;
  outline: none;
  text-align: center;

  outline: none;
  border: none;
  background: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #ce373a;
  }
  & > img {
    width: 20px;
    height: 20px;
  }

  & > button {
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}

.list {
  border: 1px solid black;
  position: absolute;
  padding: 10px 30px;
  left: 50%;
  top: 50px;
  transform: translate(-50%);
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  width: 250px;
  text-align: center;
  background: #fff;
  max-height: 500px;
  overflow-x: scroll;
}

.list::-webkit-scrollbar {
  display: none;
}
.selBtn:hover {
  color: #ce373a;
}

.selBtn {
  font-size: 16px;
  margin-top: 12px;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
  display: block;
  cursor: pointer;
  text-align: center;
}

.langWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > img {
    width: 25px;
    height: 25px;
    position: relative;
  }
}

.current {
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > img {
    position: relative;
  }
}

.langList {
  position: absolute;
  background: white;
  padding: 15px;
  border-radius: 10px;
  left: 50%;
  top: 50px;
  transform: translate(-50%);
}

.selectBtn {
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;

  &:hover {
    color: #ce373a;
  }
}

@media screen and (max-width: 1340px) {
  .selectBtn {
    font-size: 12px;
  }
}

@media screen and (max-width: 660px) {
  .select {
    position: static;
  }
  .selectBtn {
    font-size: 11px;
  }
  .selBtn {
    font-size: 14px;
  }
}
