.title {
  font-size: 20px;
  font-weight: bold;
  color: gray;

  & > span {
    font-size: 16px;
    margin-left: 10px;
    color: black;
  }
}

.status {
  font-weight: bold;

  display: flex;
  align-items: center;

  & > img {
    width: 25px;
    margin-right: 5px;
  }
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    width: 25px;
    transition: all 0.5s;
  }
}

.blockItem {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  padding: 40px 40px 10px 40px;
 
}
.blockItem2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  padding: 40px 40px 10px 40px;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
}

.date {
  display: flex;
  justify-content: start;
  font-size: 12px;
}
.noAnswer {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rotate {
  transform: rotate(180deg);
}

.answer {
  font-size: 20px;
  font-weight: bold;
  color: gray;
}

.doc {
  

  & > p {
    font-style: italic;
    font-size: 13px;
  }

  & > div >a {
    font-size: 13px;
  }
}

.viewImg {
  display: inline-block;
   
  & > a img {
    width: 300px;
    height: 250px;
    object-fit:cover;
    margin: 0 10px;
    border-radius: 5px;
    margin: 5px 5px;
   
  }
 
}

 

@media screen and (max-width: 550px) {
  .blockItem {
 
    padding: 20px;
   
  }
  .blockItem2 {
 
    padding: 20px;
max-width: 88%;
    margin: 0 auto ;
  }
}