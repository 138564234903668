@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.queue {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  justify-content: space-between;
  align-items: center;
}

.container {
  width: 100%;
  padding: 0 40px;
  &:last-child {
    height: 100%;
  }
}

.speakerNow {
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 30px;
  overflow: hidden;
}

.avatar {
  display: flex;
  justify-content: center;
  & > img {
    border: 10px solid rgb(251, 173, 50);
    width: 350px;
    height: 350px;
    border-radius: 100%;
    object-fit: contain;
    background: white;
  }

  animation: leaves 1.5s ease-in-out infinite alternate;
}

.name {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: white;
  margin: 25px 0;
  text-shadow: 0 0 8px black;
}

.text {
  text-align: center;
  font-size: 60px;
  margin: 10px 0;
  font-weight: bold;
}

.number {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 80px;
  margin: 0;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 5px;
  color: white;
  min-width: 120px;
  text-align: center;
  text-shadow: 0 0 5px black;
}

.location {
  text-align: center;
  font-size: 40px;
  color: white;
  text-shadow: 0 0 8px black;
}

.montain {
  position: absolute;
  right: -200px;
  bottom: 0;
  z-index: -1;
}

.speaker {
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 15px 10px;
}

.smallName {
  font-size: 38px;
  font-weight: bold;
  margin: 0;
  text-shadow: 0 0 8px black;
  color: white;
}
.smallLocation {
  color: white;
  font-size: 30px;
  margin: 10px 0;
  text-shadow: 0 0 8px black;
}
.smallAvatar {
  & > img {
    width: 190px;
    height: 190px;
    border-radius: 100%;
    margin: 0 20px;
    object-fit: contain;
    background: white;
  }
}
.list {
  overflow-y: scroll;
  height: 90%;
}

.list::-webkit-scrollbar {
  display: none;
}

.smallNumber {
  text-shadow: 0 0 5px black;
  font-size: 55px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 20px;
  color: white;
  min-width: 94px;
  text-align: center;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.curlogo {
  position: absolute;
  width: 130px;
}

@keyframes leaves {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}
