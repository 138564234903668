.btn {
  position: fixed;
  background: rgb(228, 217, 200);
  width: 50px;
  z-index: 999;
  height: 50px;
  bottom: 40px;
  right: 40px;
  border-radius: 100%;
  box-shadow: 0px 20px 50px 0px rgba(190, 131, 131, 0.1);
  border: 1px solid #f2f2f2;
  color: white;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  & > img {
    transform: rotate(180deg);
    width: 25px;
  }
}
