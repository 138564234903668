.section {
  width: 96%;
  margin: 0 auto;
  overflow-x: hidden;

  &:first-child {
    width: 100%;
  }
  &:nth-child(3) {
    width: 100%;
  }

  &:nth-child(7) {
    width: 100%;
  }
}
