.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  grid-column: 3;
  width: 90%;
  margin: 30px auto;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  padding-bottom: 30px;
}

.menu {
  max-width: 90%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div > button {
    display: flex;
    font-weight: bold;

    cursor: pointer;
    font-size: 14px;
    border: none;
    background: none;
    padding: 5px;
    height: 25px;
    align-items: center;
    justify-content: center;

    & > img {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }
  & > button:hover {
    color: orange;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuItem {
  position: relative;
}

.dropMenu {
  width: 150px;
  top: 30px;
  left: 50%;
  position: absolute;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  max-height: 500px;
  z-index: 9;
}

.listItem:nth-child(3) {
  margin-top: 0;
}

.listItem {
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 14px;
  text-align: start;
}
.listItem:hover {
  color: red;
}

@media screen and (max-width: 900px) {
  .menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .menuItem {
    margin-top: 10px;
  }
  .dropMenu {
    top: 30px;
    left: 30%;
  }
  .cards {
    display: block;
  }
}
