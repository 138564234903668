.openMenuIcon {
  display: flex;
  justify-content: end;

  & > button {
    background: none;
    border: none;
    outline: none;
    margin-right: 20px;

    & > img {
      width: 35px;
    }
  }
}

.menu {
  position: absolute;
  width: 100%;
  padding: 20px 0;
  background: white;
  left: 0;
  top: 0;
}

.menuList {
  & > div > button {
    margin-top: 10px;
    list-style-type: none;
    border: none;
    outline: none;
    font-weight: bold;
    background: none;
    color: red;
    text-decoration: underline;
  }

  text-align: center;
}

.navBtns {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  & > button {
    margin: 0 10px;
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: red;
  }
}

.langs {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  & > button {
    margin: 0 10px;
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
  }
}
