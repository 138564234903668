.card {
  width: 65%;
  margin: 0 auto;
  box-shadow: 0px 10.306px 25.766px 0px rgba(190, 131, 131, 0.1);
  border: 1px solid rgb(241, 239, 239);
  padding: 50px 20px;
  border-radius: 10px;
}
.content {
  display: flex;
  justify-content: space-around;
}

.stats {
  width: 550px;
}

.nav {
  background: rgba(206, 55, 58, 0.1);
  padding: 10px;
  display: flex;
  border-radius: 13.846px;
}

.switcher {
  outline: none;
  border: none;
  border-radius: 13.846px;
  background: none;
  width: 100%;
  height: 55.385px;
  cursor: pointer;
  color: #fff;
  color: black;
  font-size: 16.615px;
}
.active {
  background: #ce373a;
  color: white;
}

.statCard {
  margin-top: 25px;
}

.to {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & > button {
    padding: 16px 120px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #ce373a;
    border: none;
    outline: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1100px) {
  .card {
    width: auto;
    margin: 0;
  }
}

@media screen and (max-width: 650px) {
  .content {
    display: block;
  }
  .stats {
    width: auto;
  }
  .switcher {
    height: 40px;
    font-size: 15px;
  }
  .to {
    & > button {
      padding: 20px;
    }
  }
}
