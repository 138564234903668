.speakerNow {
  width: 100%;
}

.timer {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border-radius: 10px;
  font-size: 55px;
  font-weight: bold;
  z-index: 9999;
  cursor: pointer;
  user-select: none;
  background: rgba(255, 255, 255, 0.225);
  padding: 10px;
}

.delegat {
  width: 1000px;
  position: relative;
  padding: 15px 30px;
  display: flex;
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  color: white;

  & > div > img {
    width: 250px;
    border-radius: 100%;
    object-fit: cover;
    height: 250px;
    padding: 10px;
    border: 1px solid white;
  }

  & > div {
    align-items: center;
    display: flex;
  }
  & > div div {
    margin-left: 30px;
  }
  animation: shadow 5s infinite;
}
.uzor {
  position: absolute;
  opacity: 0.6;
  width: 100%;
  top: 0;
  left: 0;
}

.smallDelegat {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: 1px solid var(--12, #ce373a);
  margin-top: 10px;
  max-width: 600px;
  padding: 15px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  color: white;

  &:first-child {
    margin-top: 0;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & > div > img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    margin-left: 10px;
  }

  & > div p {
    margin: 0;
  }

  & > div p:first-child {
    font-weight: bold;
    font-size: 18px;
  }

  & > div p:last-child {
    font-size: 17px;
  }

  & > div > div {
    margin-left: 20px;
  }
}

.key {
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.name {
  font-weight: bold;
  font-size: 30px;
}

.location {
  font-size: 22px;
}

.birthDate {
  font-size: 14px;
  font-weight: bold;
}

.others {
  display: flex;
  margin-top: 40px;
  justify-content: space-evenly;
  align-items: center;
}

.key {
  font-weight: bold;
  font-size: 30px;
}

.mainKey {
  color: white;
  font-size: 60px;
  font-weight: bold;
  width: 100px;
  height: 100px;
  border-radius: 30%;
  justify-content: center;
  background: rgba(255, 255, 255, 0.225);
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin: 15px;
}

.scroll {
  overflow-y: scroll;
  padding: 0 5px;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 496px;
}

.montain {
  position: absolute;
  left: 200px;
}

.modal {
  width: 500px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > input {
    width: 93%;
    padding: 15px;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }

  & > .btn {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    & > button {
      width: 100px;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 10px;
      font-weight: bold;
      outline: none;
      border: none;
      background-color: #f9a51a;
      color: white;
      font-size: 16px;
    }
  }
}

.nav {
  width: 100px;
  padding: 16px;
  cursor: pointer;
  font-size: 30px;
  border: none;
  outline: none;
  margin: 0 10px;
  border-radius: 10px;
  background: none;
  height: 50px;
  z-index: 99999;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .timer {
    bottom: 20px;
    right: 10px;
    font-size: 30px;
  }

  .willSpeakers {
    margin: 0 auto;
  }

  .montain {
    top: 0;
    left: -300px;
  }

  .delegat {
    position: relative;
    display: block;
    padding: 10px 15px;
    width: 96%;
    border-radius: 10px;
    & > div > img {
      width: 100px;
      height: 100px;
      border: none;
    }

    & > div {
      display: block;
    }
    & > div div {
      margin-left: 10px;
    }
    & > div p {
      font-size: 16px;
    }
  }

  .mainKey {
    font-size: 30px;
    border: none;
    padding: 0;
    position: absolute;
    top: 40px;
    right: 5px;
    text-align: center;
    background: none;
  }

  .key {
    font-size: 16px;
  }

  .smallDelegat {
    border-radius: 0;
    padding: 5px;
    border-radius: 10px;
    & > div > img {
      margin-left: 0px;
    }

    & > div > div > p:first-child {
      font-size: 16px;
    }

    & > div > div > p:last-child {
      font-size: 14px;
    }
  }

  .scroll {
    height: 600px;
  }
}
.shake {
  animation: shake 0.1s alternate;
}

@keyframes shake {
  0% {
    transform: translate(-2px, 2px);
  }
  33% {
    transform: translate(-2px, 4px);
  }
  66% {
    transform: translate(-3px, 3px);
  }
  100% {
    transform: translate(-3px, 3px);
  }
}
