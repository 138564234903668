.splash {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  & > div {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: splash;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    transition: all 0.5s;
    z-index: 999;
    box-shadow: 0px 20px 50px 0px rgba(190, 131, 131, 0.1);
    border-radius: 100%;
  }
}

.wave {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 100%;
  height: 100%;
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  overflow: hidden;
  z-index: -1;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.5);
}

.wave:before,
.wave:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 200%;
  background: black;
  transform: translate(-50%, -75%);
}
.wave:before {
  border-radius: 45%;
  background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
  animation: animate 5s linear infinite;
}
.wave:after {
  border-radius: 40%;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes splash {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}
