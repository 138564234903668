.text {
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .text {
    font-size: 24px;
  }
}
