.map {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  align-items: center;

  & > div > svg {
    & > path:hover {
      fill: #f2e55b;
      stroke: #f2e55b;
      cursor: pointer;
      transition: all 0.5s;
    }
  }

  & > div > svg > g {
    & > path:hover {
      fill: #f2e55b;
      stroke: #f2e55b;
      cursor: pointer;
      transition: all 0.5s;
    }
  }
}

.selected {
  fill: #f9a51a;
  stroke: #f9a51a;
  cursor: pointer;
  transition: all 0.5s;
}

.mapRow {
  width: 1000px;
  height: 400px;
}

// .reset {
//   border: none;
//   outline: none;
//   padding: 10px;
//   border-radius: 10px;
//   border: 1px solid #ce373a;
//   color: black;
//   cursor: pointer;
//   transition: all 0.5s;
//   background: none;

//   &:hover {
//     border: 1px solid #f9a51a;
//   }
// }

// .btnReset {
//   background: #ce373a;
//   color: white;
// }

@media screen and (max-width: 950px) {
  .map {
    display: block;
    width: auto;
    margin: 10px auto;
  }

  .mapRow {
    margin-top: 20px;
    width: 100%;
    height: 250px;
    transform: scale(1.1);
  }
}
