@use "shared/styles/global.scss" as *;

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--12, linear-gradient(180deg, #f9a51a 0%, #ce373a 100%));
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  height: 548px;
  & a {
    text-decoration: none;
  }
}

.order {
  position: relative;
  width: 350px;
  height: 350px;
  margin: 0 30px;
  border-radius: 20px;
  z-index: 999;
  cursor: pointer;

  & img {
    margin: 30px 25px;
  }

  & p {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    bottom: 0;
    color: $color-white;
    margin: 0px 15px;
    text-align: left;
    text-transform: uppercase;
  }

  &::before {
    position: absolute;
    display: block;
    background: $color-white;
    width: 350px;
    height: 350px;
    content: "";
    opacity: 0.1;
    z-index: 1;
    border-radius: 20px;
  }
}

.text {
  margin-top: 100px;
  margin-bottom: 70px;
}

@media screen and (max-width: 1100px) {
  .content {
    height: auto;
    border-radius: 10px;
    padding: 1px 0;
    width: 100%;
  }
  .order {
    width: 200px;
    height: 280px;
    margin: 20px 10px;
    &::before {
      width: 200px;
      height: 280px;
    }
    & p {
      font-size: 12px;
      margin: 10px;
    }

    & img {
      width: 60px;
      margin: 10px;
    }
  }
}
@media screen and (max-width: 700px) {
  .content {
    display: block;
  }

  .order {
    width: 300px;
    height: 300px;
    margin: 20px auto;
    &::before {
      width: 300px;
      height: 300px;
    }
    & p {
      font-size: 16px !important;
      margin: 0 20px;
    }

    & img {
      width: 60px;
      margin: 8px 20px;
    }
  }
}
