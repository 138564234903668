@use 'shared/styles/global.scss' as *;

.header {
	background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
	position: relative;
	background-size: contain;
	height: 548px;
	overflow: hidden;
	border-bottom-left-radius: 90%;
	border-bottom-right-radius: 90%;
	z-index: 1;
}

.simpleHeader {
	width: 100%;
	background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
	position: relative;
	background-size: contain;
	overflow: hidden;
	z-index: 1;
}

.mainText {
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
	color: $color-white;
	text-align: center;
	margin: 80px 0;
}

.logoWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0px;

	& > a > img {
		width: 180px;
	}
}

.btns {
	display: flex;
}

.textWrapper {
	display: flex;
	justify-content: center;
	z-index: 1;
	height: 150px;
	margin-top: 40px;
}

.delegateBtn {
	padding: 10px;
	font-size: 14px;
	font-weight: 700;
	line-height: normal;
	background: none;
	color: white;
	cursor: pointer;
	margin: 0px 7px;
	border: none;
}

.montain {
	position: absolute;
	bottom: -130px;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1111;
	object-fit: contain;
	width: 100vw;
}

.slider {
	position: relative;
	top: -100px;
	z-index: 1;
}

.select {
	display: flex;
	position: relative;
	top: -40px;
	justify-content: center;
	z-index: 1;
}

.LangSelect {
	margin-right: 20px;
}

.wrapper {
	z-index: 4;
	top: -30px;
	position: relative;
	width: 1240px;
	padding: 20px 60px;
	background: $color-white;
	box-shadow: 0px 20px 50px 0px rgba(190, 131, 131, 0.1);
	border-radius: 20px;
	display: flex;
	justify-content: space-around;
	margin: 0 auto;
}

.nav {
	display: flex;
	margin-right: 70px;
	& > ul {
		display: flex;
		align-items: center;
		& > a {
			text-decoration: none;
		}

		& > a > li {
			margin: 0 20px;
			list-style-type: none;
			font-weight: bold;
			color: white;
			cursor: pointer;
			&:hover {
				color: darkgray;
			}
		}
	}
}

.burgerBtns {
	display: flex;
	justify-content: center;
	align-items: center;
}

.burgerBtn {
	margin-right: 30px;
	background: none;
	outline: none;
	border: none;
	display: none;
	z-index: 7;
	& > img {
		width: 30px;
	}
}

.burgerMenu {
	position: absolute;
	background: white;
	width: 100%;
	padding: 100px 0;
	z-index: 6;
}

.backdrop {
	background: black;
	opacity: 0.5;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 5;
}

.burgerMenuContent {
	text-align: center;
	& > a > li {
		margin-top: 20px;
		list-style-type: none;
		font-weight: bold;
	}

	& > a {
		text-decoration: none;
		color: black;
	}
}

@media screen and (max-width: 1300px) {
	.nav {
		display: none;
	}
	.btns {
		display: none;
	}

	.burgerBtn {
		display: block;
	}
	.montain {
		bottom: 0;
	}
}

@media screen and (max-width: 1340px) {
	.wrapper {
		width: auto;
		padding: 10px 5px;
	}
	.header {
		border-bottom-left-radius: 90%;
		border-bottom-right-radius: 90%;
	}
}
@media screen and (max-width: 1200px) {
	.wrapper {
		width: auto;
		padding: 10px 5px;
	}
	.header {
		border-bottom-left-radius: 70%;
		border-bottom-right-radius: 70%;
	}
	.montain {
		bottom: 0;
	}
}
@media screen and (max-width: 660px) {
	.header {
		height: 450px;
	}

	.mainText {
		font-size: 30px;
		margin: 20px 0 60px 0;
	}
	.logoWrapper {
		& a > img {
			width: 160px;
			height: 60px;
		}

		& > div > button {
			width: auto;
			font-size: 14px;
			margin-right: 20px;
		}
	}

	.textWrapper {
		& > img {
			width: 200px;
			height: 100px;
		}
	}
	.montain {
		bottom: 0;
	}
}
@media screen and (max-width: 420px) {
	.logoWrapper {
		& > div {
			& > button {
				padding: 10px;
			}

			& > div {
				margin-right: 0;
			}
		}
	}
}
