@use 'shared/styles/global.scss' as *;

.title {
	margin: 140px 0 70px 0;
}

.delegat {
	cursor: pointer;
	padding: 10px;

	& > p {
		text-align: center;
		margin: 0;
	}
	& p[name='name'] {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-top: 10px;
	}

	& p[name='where'] {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	& > div {
		display: flex;
		justify-content: center;
	}
	& > div img {
		border-radius: 100%;
		width: 160px;
		height: 160px;
		object-fit: contain;
		border: 2px solid none;
		padding: 10px;
		background: white;
	}

	& > div img:hover {
		transition: all 0.5s;
		transform: scale(1.1);
	}

	&:hover {
		p {
			color: red;
			transition: all 0.3s;
		}
	}
}

.slider {
	max-width: 1300px;
	margin: 50px auto;
}

.bgClassName {
	position: absolute;
	width: 100%;
	background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));
	height: 70px;
	top: 0;
	left: 0;
	z-index: -1;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	opacity: 1;
	overflow: hidden;
	opacity: 0.9;
	& > img {
		width: 100%;
	}
}

.sliderArrow {
	display: flex;
	align-items: center;
	max-width: 1500px;
}

.delegateItem {
	position: relative;
	padding: 20px 30px;
	max-width: 300px;
	box-shadow: 0px 10px 10px 0px rgba(73, 73, 72, 0.1);
	border: 1px solid rgba(125, 124, 121, 0.1);
	border-radius: 20px;
	margin: 0 auto;
	cursor: pointer;
	height: 180px;

	& > div > p {
		margin: 0;
	}

	& > div > p[name='name'] {
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		font-size: 18px;
		margin-top: 18px;
	}
	& > div > p[name='whereFrom'] {
		color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-top: 7px;
	}

	& > img {
		width: 80px;
		height: 80px;
		border-radius: 100px;
		object-fit: contain;
		background: white;
	}
}

.pickDate {
	max-width: 1250px;
	margin: 40px auto 0 auto;
	display: flex;
	justify-content: space-between;
}

.searchBlock {
	display: flex;
	align-items: center;
	border: 1px solid darkgray;
	border-radius: 10px;
	padding: 5px 10px;
}

.search {
	border-radius: 10px;
	outline: none;
	border: none;
	margin-left: 10px;
	background: transparent;
}

.searchIcon {
	width: 20px;
	cursor: pointer;
	align-items: center;
}

.date {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dateBtn {
	border-radius: 10px;
	background: #f5f5f5;
	padding: 10px 20px;
	margin: 0 10px;
	font-weight: bold;
	font-size: 16px;

	border: none;
	color: #747474;
	cursor: pointer;
}
.dateBtn:hover {
	color: red;
}

.dateBtnActive {
	background: #f4caca;
	color: red;
}

.dateText {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.loading {
	display: flex;
	justify-content: center;
	margin: 100px 0;
}

.sliderNav {
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 79%;
}

.loading {
	width: 40px;
	height: 20px;
}

.content {
	& > div > div > div {
		&:last-child {
			bottom: -10px;
			position: relative;
			padding: 10px 0;

			& > span {
				width: 11px;
				height: 11px;
        background: #f9a51a;
			}
		}
	}
	& > div {
		margin: 0 auto;
	}
}

@media screen and (max-width: 1340px) {
	.btnWrapper {
		display: none;
	}
}

@media screen and (max-width: 1340px) {
	.wrapper {
		width: auto;
		padding: 10px 5px;
	}
	.sliderNav {
		width: 100%;
	}
}
@media screen and (max-width: 660px) {
	.title {
	    margin: 72px 0 8px 0;
    }
	.delegat {
	    width: 400px;
	}
	.searchBlock {
		margin-top: 20px;
	}
	.pickDate {
		padding: 0 10px;
		display: block;
	}
	.dateText {
		font-size: 14px;
	}
	.dateBtn {
		padding: 10px;
		font-size: 12px;
	}
	.delegat {
		& > p[name='name'] {
			font-size: 16px;
		}
		& > p[name='whereFrom'] {
			font-size: 14px;
		}

		& > div > img {
			width: 120px;
			height: 120px;
			border-radius: 100px;
			object-fit: cover;
		}
	}
}
