@use 'shared/styles/global.scss' as *;

#curultai {
	margin-top: -100px;
}

.curultai {
	display: flex;
	justify-content: space-between;
	position: relative;
	align-items: center;
	background: linear-gradient(5deg, #ce373a 2.51%, #f9a51a 94.33%);
	max-width: 1100px;
	margin: 0 auto;
	border-radius: 20px;
	padding: 70px 50px;
	z-index: 1;
	overflow: hidden;

	& > img {
		transition: all 0.5s;
		position: absolute;
		right: 0;
		z-index: -1;
	}
	& > div {
		width: 396px;
	}
	&:hover {
		img {
			transform: scale(1.4);
		}
	}

	& > div > p {
		color: $color-white;
	}
	& > p {
		color: $color-white;
		margin: 0;
	}
}

.date {
	font-size: 40px;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	text-transform: uppercase;
}

.when {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 24px;
}

.desc {
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	width: 606px;
}

.title {
	margin-bottom: 70px;
}

@media screen and (max-width: 950px) {
	.curultai {
		display: block;

		& > div {
			width: auto;
		}
		& > img {
			right: 0;
			top: 0;
			z-index: -1;
		}
	}

	.desc {
		width: auto;
		font-size: 16px;
	}
	.date {
		font-size: 24px;
		text-align: center;
	}

	.when {
		font-size: 16px;
	}
}
