.num {
  position: absolute;
  z-index: 99;
  font-size: 22px;
  opacity: 0.7;
  font-weight: bold;
  bottom: 7px;
  right: 25px;
  color: darkgray;
  font-style: oblique;
  transform: rotate(5deg);
}
