.footer {
	background: var(--12, linear-gradient(180deg, #ce373a 0%, #f9a51a 100%));

	background-size: contain;

	margin-top: 150px;

	padding: 20px 10px 0 10px;
}

.logo {
	display: flex;
	justify-content: center;
	height: 90px;
}

.list {
	margin: 0;
	padding: 0;

	& > li {
		list-style-type: none;
		margin-top: 5px;
		font-size: 13px;
		width: 280px;
		color: white;
		& > a {
			color: white;
			text-decoration: none;
		}
	}
}

.contact {
	max-width: 570px;
	color: white;

	font-size: 13px;

	& > h4 {
		font-size: 16px;
		font-weight: bold;
	}
}

.flex {
	display: flex;
	align-items: start;
	justify-content: space-around;

	& > div > ul li {
		font-size: 13px;
	}

	& > div > h5 {
		color: white;
		font-size: 16px;
		font-weight: bold;
	}
}

.tunduk {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	& > img {
		width: 40px;
		margin-top: 10px;
	}

	& > span {
		color: white;
		font-size: 14px;
		margin-left: 10px;
		margin-top: 12px;
	}
}

@media screen and (max-width: 660px) {
	.footer {
		& > img {
			width: 200px;
		}
	}
	.info {
		& div {
			margin: 0;
		}
	}
}
@media screen and (max-width: 1050px) {
	.flex {
		display: block;
	}
}

@media screen and (max-width: 900px) {
	.footer {
		display: block;
	}

	.contact {
		width: auto;
		padding: 0;
	}
	.info {
		display: block;
		& > div {
			margin: 0;
			display: block;

			&:last-child {
				margin: 0;
			}
		}
	}
}
