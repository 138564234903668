.mainCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.mapRow {
  width: 750px;
}
@media screen and (max-width: 1250px) {
  .mapRow {
    width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .mapRow {
    width: 320px;
  }

  .mainCard {
    display: block;
  }
}
