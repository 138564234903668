.round {
  width: 70%;
  margin: 0 auto;
  height: 100%;
}

.bar {
  margin-top: 70px;
  width: 100%;
}
.bar1 {
  margin-top: 40px;
  width: 100%;
}

.bigRound {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 700px;
  position: relative;
}

.category {
  width: 96%;
  height: 100%;
}

.text {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
