.slider {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 1300px;
	margin: 0 auto;
}

.btn {
	background: none;
	outline: none;
	cursor: pointer;
	border: none;
}

.slide {
	display: flex;
	justify-content: center;
	cursor: grab;

	& > img {
		width: 380px;
		height: 330px;
		border-radius: 20px;
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}
}

@media screen and (min-width: 1960px) {
	.swiper {
		width: 1200px;
	}
}

@media screen and (max-width: 1950px) {
	.swiper {
		z-index: 1;
		width: 1000px;
	}

	.slide {
		& > img {
			width: 300px;
			height: 300px;
		}
	}
}
@media screen and (max-width: 1000px) {
	.slider {
		width: 100%;
	}

	.swiper {
		width: 800px;
	}

	.slide {
		& > img {
			width: 250px;
			height: 250px;
		}
	}
}
@media screen and (max-width: 740px) {
	.slider {
		width: 100%;
	}

	.swiper {
		width: 600px;
	}

	.slide {
		& > img {
			width: 200px;
			height: 200px;
		}
	}
}
@media screen and (max-width: 600px) {
	.btn {
		& > img {
			height: 30px;
			width: 30px;
		}
	}
	.slider {
		width: 100%;
	}

	.swiper {
		width: 450px;
	}

	.slide {
		& > img {
			width: 150px;
			height: 150px;
		}
	}
}

@media screen and (max-width: 500px) {
	.slider {
		width: 100%;
	}

	.swiper {
		width: 400px;
	}

	.slide {
		& > img {
			width: 140px;
			height: 125px;
		}
	}
}
