.region {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  padding: 20px 25px;
  border-radius: 10px;
  position: relative;
}

.title {
  & p {
    text-align: center;
    font-weight: bold;
    & > span {
      font-size: 12px;
      font-style: italic;

      text-align: end;
    }
  }
}

.card:nth-child(1) {
  grid-column: span 6;
}

.card:nth-child(2) {
  grid-column: span 2;
}
.card:nth-child(3) {
  grid-column: span 2;
}

.card:nth-child(4) {
  grid-column: span 2;
}

.card:nth-child(5) {
  grid-column: span 3;
}
.card:nth-child(6) {
  grid-column: span 3;
}
.card:nth-child(7) {
  grid-column: span 3;
}
.card:nth-child(8) {
  grid-column: span 3;
}

@media screen and (max-width: 1400px) {
  .region {
    display: block;
  }
}
