.notify {
  width: 500px;
  padding: 20px;
  position: fixed;
  bottom: -500px;
  left: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  background: white;
  box-shadow: 0px 10px 40px 0px rgba(190, 131, 131, 0.1);
  transition: all 0.5s;
  border: 3px solid whitesmoke;
  font-weight: bold;
}

.close {
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;

  & > img {
    width: 20px;
  }
}

.view {
  bottom: 30px;
}

@media screen and (max-width: 500px) {
  .notify {
    width: auto;
    left: 0;
  }
}
