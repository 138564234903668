.wrapper {
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
  & > button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.iframe {
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  width: 1000px;
  height: 500px;
}

.title {
  margin-top: 140px;
}

@media screen and (max-width: 1360px) {
  .wrapper {
    width: auto;
  }
  .iframe {
    width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .iframe {
    width: 500px;
  }
}
@media screen and (max-width: 750px) {
  .iframe {
    width: 300px;
    height: 300px;
  }
}
