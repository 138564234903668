.btn {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
}


@media screen and (max-width: 660px) {
	.btn {
		display: none;
	}
}