.menu {
  padding: 20px 0;
  display: block;
  margin-right: 40px;
}

.point {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.btn {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5;

  &:first-child {
    margin-left: 0;
  }
}

.totalCount {
  font-weight: 200;
  font-size: 12px;
  font-style: italic;
}

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
